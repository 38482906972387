import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  Link
} from "react-router-dom";
import flag from './images/flag.png';
import catlandMap from './images/map.jpg';
import './catland.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />
  },
  {
    path: "/map",
    element: <Map />,
  },
]);

function Catland() {
  return (
    <React.StrictMode>
      <div className="catland">
        <RouterProvider router={router} />
      </div>
    </React.StrictMode>
  )
}

function Header() {
  return (
    <>
      <header className="header">
        <img src={flag} className="catland-flag" alt="Flag of Catland" />
      </header>
      <nav>
        <ul className="nav-menu">
          <li>
            <Link to={'/'}>🏠</Link>
          </li>
          <li>
            <Link to={`/map`}>🗺️</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

function Main() {
  return (
    <>
      <Header />
      <h1>Welcome the Catland! Добро пожаловать в Кошколандию! Murr meow!</h1>
    </>
  );
}

function Map() {
  return (
    <>
      <Header />
      <h1>Карта Кошколандии</h1>
      <img src={catlandMap} className="catland-map" alt="Map of Catland" />
    </>
  );
}

function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Meow!</h1>
      <p>Страница не найдена, мурр!</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default Catland;
